export default function (other) {
  other = other || {}
  return {
    id: other.id,
    name: other.name,
    companyPosition: other.companyPosition,
    permissions: other.permissions || [],
    active: other.active,
  }
}
