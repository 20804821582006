<template>
  <portal to="breadcrumb-right">
    <div style="letter-spacing: 5px">
      <slot name="beforeDropdown"></slot>
      <b-dropdown variant="link" no-caret toggle-class="p-0" right v-if="hideSettings === false">
        <template #button-content>
          <b-button variant="primary" class="btn-icon">
            <feather-icon icon="SettingsIcon"/>
          </b-button>
        </template>
        <slot name="items"></slot>
      </b-dropdown>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'breadcrumbButton',
  props:{
    hideSettings: {
      type: Boolean,
      default: ()=>false
    }
  }
}
</script>

<style scoped>

</style>
