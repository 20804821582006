<template>
  <div>
    <div>
      <div v-for="category in permissionsList" v-bind:key="category">
        <h4 class="text-center" @dblclick="()=>setAllInCategory(category)">{{ allPermissionsByCategory[category].title }}</h4>
        <b-card no-body>
          <b-table-simple small responsive>
            <b-thead head-variant="light">
              <b-tr>
                <b-th style="width: 40%">{{ $t('columns.permissions') }}</b-th>
                <b-th style="width: 40%">{{ $t('columns.description') }}</b-th>
                <b-th>{{ $t('columns.active') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="permission in allPermissionsByCategory[category].permissions" v-bind:key="permission.name">
                <b-td>{{ permission.name }}</b-td>
                <b-td>{{ permission.description }}</b-td>
                <b-td>
                  <b-form-checkbox name="check-button" v-model="permissionSelection[permission.name]" switch @change="updatePermissions"/>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PermissionsTable',
  props: ['value'],
  data:()=>({
    permissionSelection: {},
  }),
  watch: {
    value() {
      this.refresh()
    }
  },
  created() {
    this.refresh()
  },
  computed: {
    permissions: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    allPermissionsByCategory() {
      return this.$t('permissions')
    },
    permissionsList() {
      return Object.values(this.allPermissionsByCategory)
          .sort((p1, p2) => p1.index - p2.index)
          .map(p => p.type)
    },
    allPermissions() {
      let permissions = {}
      this.permissionsList.forEach(category => {
        this.allPermissionsByCategory[category].permissions.forEach(permission => {
          permissions[permission.name] = permission
        })
      })
      return permissions
    }
  },
  methods: {
    refresh() {
      let permissions = this.permissions || [];
      this.permissionSelection = Object.keys(this.allPermissions).map(key => ({ name: key, value: permissions.includes(key) }))
          .reduce((map, obj) => {
            map[obj.name] = obj.value
            return map
          }, {})

    },
    setAllInCategory(category){
      let permissionsCategory = this.allPermissionsByCategory[category]
      let isAllTrue = permissionsCategory.permissions.every(permission => this.permissionSelection[permission.name])
      permissionsCategory.permissions.forEach(permission => {
        this.permissionSelection[permission.name] = !isAllTrue
      })
      this.updatePermissions()
    },
    updatePermissions() {
      this.permissions = Object.keys(this.permissionSelection)
          .filter(key => this.permissionSelection[key])
    }
  }
}
</script>


<style scoped>

</style>
