<template>
  <div>
    <breadcrumb-button hide-settings>
      <template #beforeDropdown>
        <b-button variant="success" class="btn-icon" v-b-tooltip v-bind:title="$t('operations.add')" @click="$refs['role-modal'].show()">
          <i class="fa-solid fa-user-plus"></i>
        </b-button>
      </template>
    </breadcrumb-button>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="4">
            <label>{{ $t('columns.role') }}</label>
            <v-select style="width: 100%;"
                      v-model="selectedRoleId"
                      :options="roles"
                      :reduce="role => role.id"
                      label="name" placeholder="Select a role"
                      :clearable="false"
                      :disabled="loading"
            />
          </b-col>
          <b-col cols="4">
            <label>{{ $t('columns.position') }}</label>
            <v-select style="width: 100%;"
                      v-model="selectedRole.companyPosition"
                      :options="rolesPositions"
                      :reduce="role => role.id"
                      label="name"
                      placeholder="Select a role"
                      :clearable="false"
                      :disabled="loading"
            />
          </b-col>
          <b-col cols="2">
            <label style="margin-top: 12px">{{ $t('columns.active') }}</label>
            <b-form-checkbox name="check-button" v-model="selectedRole.active" switch/>
          </b-col>
          <b-col cols="2" style="margin-top: 8px">
            <b-button block size="sm" variant="primary" class="mt-2" @click="save">
              {{ $t('operations.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <permissions-table v-model="selectedRole.permissions"/>
    <role-modal ref="role-modal"/>
  </div>
</template>

<script>
import PermissionsTable from '@/components/partial/roles/PermissionsTable.vue'
import { mapActions } from 'vuex'
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import Role from '@/model/role/Role'
import RoleModal from '@/components/modal/RoleModal.vue'
import BreadcrumbButton from '@/components/widget/breadcrumbButton.vue'
import mixinTable from '@/components/mixin/mixinTable'


export default {
  name: 'RolesView',
  mixins: [mixinBase, mixinNotifications, mixinTable],
  components: { BreadcrumbButton, RoleModal, PermissionsTable },
  data: () => ({
    roles: [],
    selectedRole: Role(),
    selectedRoleId: null,
    loading: false
  }),
  created() {
    this.refresh()
  },
  watch: {
    selectedRoleId() {
      this.onRolePositionChanged()
    }
  },
  computed: {
    rolesPositions() {
      let positions = this.$t('roles.position')
      return Object.keys(positions)
          .map(key => ({
            id: key,
            name: positions[key].name,
            index: positions[key].index
          }))
          .sort((p1, p2) => p1.index - p2.index)
    },
  },
  methods: {
    ...mapActions('role', ['getAllRoles', 'getById', 'updateRole']),
    async refresh() {
      try {
        this.loading = true
        this.roles = await this.getAllRoles()
        if (this.roles.length > 0 && !this.selectedRoleId) {
          this.setRole(this.roles[0].id)
        }
      } catch (error) {
        this.showServerErrorNotification('fetchDataError', 'role', error)
      } finally {
        this.loading = false
      }
    },
    async setRole(roleId) {
      try {
        console.log("setRole", roleId)
        this.loading = true
        this.selectedRole = await this.getById(roleId)
        this.selectedRoleId = this.selectedRole.id
      } catch (error) {
        this.selectedRole = Role()
        this.selectedRoleId = null
        this.showServerErrorNotification('fetchDataError', 'role', error)
      } finally {
        this.loading = false
      }
    },
    onRolePositionChanged() {
      console.log("onRolePositionChanged", this.selectedRoleId)
      if(!this.selectedRoleId) return
      if(this.selectedRole && this.selectedRole.id === this.selectedRoleId) return
      let role = this.roles.find(role => role.id === this.selectedRoleId)
      if (role) {
        this.setRole(role.id)
      } else {
        this.selectedRole = Role()
        this.selectedRoleId = null
      }
    },
    async save() {
      try {
        console.log("save")
        this.loading = true
        this.selectedRole =  await this.updateRole({ role: this.selectedRole, id: this.selectedRoleId })
        this.selectedRoleId = this.selectedRole.id;
        this.showSuccessNotification('updateSuccess', 'role')
      } catch (error) {
        this.showServerErrorNotification('updateError', 'role', error)
      } finally {
        this.loading = false
      }
    }
  },
}
</script>


<style scoped>

</style>
