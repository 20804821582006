<template>
  <div>
    <b-modal size="lg" id="role-modal" ref="role-modal" centered hide-footer no-close-on-backdrop>
      <b-row>
        <b-col cols="4">
          <label>{{ $t('columns.name') }}</label>
          <b-input v-model="role.name"  :disabled="loading"/>
        </b-col>
        <b-col cols="4">
          <label>{{ $t('columns.position') }}</label>
          <v-select style="width: 100%;"
                    v-model="role.companyPosition"
                    :options="rolesPositions"
                    :reduce="p => p.id"
                    label="name"
                    placeholder="Select a role"
                    :clearable="false"
                    :disabled="loading"
          />
        </b-col>
        <b-col cols="2">
          <label style="margin-top: 12px">{{ $t('columns.active') }}</label>
          <b-form-checkbox name="check-button" v-model="role.active" switch/>
        </b-col>
        <b-col cols="12" style="margin-top: 8px">
          <b-button block size="sm" variant="primary" class="mt-2" @click="save" :disabled="loading">
            {{ $t('operations.save') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Role from '@/model/role/Role'
import { mapActions } from 'vuex'
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'

export default {
  name: 'RoleModal',
  mixins:[mixinBase, mixinNotifications],
  data: () => ({
    role: Role({active: true, companyPosition:"SALES"}),
    loading: false,
  }),
  computed: {
    rolesPositions() {
      let positions = this.$t('roles.position')
      return Object.keys(positions)
          .map(key => ({
            id: key,
            name: positions[key].name,
            index: positions[key].index
          }))
          .sort((p1, p2) => p1.index - p2.index)
    },
  },
  methods: {
    ...mapActions('role', ['createRole']),
    async save() {
      try {
        this.loading = true
        await this.createRole(this.role)
        this.loading = false
        this.role = Role({ active: true, companyPosition: 'SALES' })
        this.$refs['role-modal'].hide()
        this.showServerSuccessNotification("createDataSuccess", "role");
        this.refreshTables()
      } catch (e) {
        this.showServerErrorNotification('createDataError', 'role', e)
      }finally {
        this.loading = false
      }

    },
    show(role) {
      this.role = role
      this.$refs['role-modal'].show()
      this.role = Role({active: true, companyPosition:"SALES"})
    }
  }
}
</script>

<style scoped>

</style>
